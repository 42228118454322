const initialState = {
    // Residence
    rightToReside: true,
    applyingAs: 'Individual',
    livingTogether: false,

    // About you
    age: 22,
    liveWithParents: false,
    paidWork: false,
    education: true,
    higherEducation: false,
    courseStart: false,
    disabled: false,
    DLA: false,
    PIP: false,
    attendanceAllowance: false,
    AFIP: false,
    severeDisabilityPremium: false,
    pregnant: false,

    // About your partner
    partnerAge: 0,
    partnerLiveWithParents: false,
    partnerPaidWork: false,
    partnerEducation: false,
    partnerHigherEducation: false,
    partnerCourseStart: false,
    partnerDisabled: false,
    partnerDLA: false,
    partnerPIP: false,
    partnerAttendanceAllowance: false,
    partnerAFIP: false,
    partnerSevereDisabilityPremium: false,
    partnerPregnant: false,

    // Dependant children
    dependantChildren: false,

    // Caring for others
    caringForOthers: false,

    // Capability to work
    limitedCapability: false,

    // Income
    parentalSupport: false,

    // Capital
    savings: '',
    investments: 0,
    ownedProperty: 0,
    assetState: [],
};

const universalCreditEligibility = (state = initialState, action) => {
    let newState;

    // Residence
    if (action.type === 'SET_RIGHT_TO_RESIDE') {
        newState = Object.assign({}, state);
        newState.rightToReside = !state.rightToReside;
        return newState;
    }
    if (action.type === 'SET_APPLYING_AS') {
        newState = Object.assign({}, state);
        newState.applyingAs = action.payload;
        return newState;
    }
    if (action.type === 'SET_LIVING_TOGETHER') {
        newState = Object.assign({}, state);
        newState.livingTogether = !state.livingTogether;
        return newState;
    }

    // About you
    if (action.type === 'SET_AGE') {
        newState = Object.assign({}, state);
        newState.age = action.payload === '' ? 0 : parseFloat(action.payload);
        return newState;
    }
    if (action.type === 'SET_LIVE_WITH_PARENTS') {
        newState = Object.assign({}, state);
        newState.liveWithParents = !state.liveWithParents;
        return newState;
    }
    if (action.type === 'SET_PAID_WORK') {
        newState = Object.assign({}, state);
        newState.paidWork = !state.paidWork;
        return newState;
    }
    if (action.type === 'SET_EDUCATION') {
        newState = Object.assign({}, state);
        newState.education = !state.education;
        return newState;
    }
    if (action.type === 'SET_HIGHER_EDUCATION') {
        newState = Object.assign({}, state);
        newState.higherEducation = !state.higherEducation;
        return newState;
    }
    if (action.type === 'SET_COURSE_START') {
        newState = Object.assign({}, state);
        newState.courseStart = !state.courseStart;
        return newState;
    }
    if (action.type === 'SET_DISABLED') {
        newState = Object.assign({}, state);
        newState.disabled = !state.disabled;
        return newState;
    }
    if (action.type === 'SET_DLA') {
        newState = Object.assign({}, state);
        newState.DLA = !state.DLA;
        return newState;
    }
    if (action.type === 'SET_PIP') {
        newState = Object.assign({}, state);
        newState.PIP = !state.PIP;
        return newState;
    }
    if (action.type === 'SET_ATTENDANCE_ALLOWANCE') {
        newState = Object.assign({}, state);
        newState.attendanceAllowance = !state.attendanceAllowance;
        return newState;
    }
    if (action.type === 'SET_AFIP') {
        newState = Object.assign({}, state);
        newState.AFIP = !state.AFIP;
        return newState;
    }
    if (action.type === 'SET_SEVERE_DISABILITY_PREMIUM') {
        newState = Object.assign({}, state);
        newState.severeDisabilityPremium = !state.severeDisabilityPremium;
        return newState;
    }
    if (action.type === 'SET_PREGNANT') {
        newState = Object.assign({}, state);
        newState.pregnant = !state.pregnant;
        return newState;
    }

    // About your partner
    if (action.type === 'SET_PARTNER_AGE') {
        newState = Object.assign({}, state);
        newState.partnerAge = action.payload === '' ? 0 : parseFloat(action.payload);
        return newState;
    }
    if (action.type === 'SET_PARTNER_LIVE_WITH_PARENTS') {
        newState = Object.assign({}, state);
        newState.partnerLiveWithParents = !state.partnerLiveWithParents;
        return newState;
    }
    if (action.type === 'SET_PARTNER_PAID_WORK') {
        newState = Object.assign({}, state);
        newState.partnerPaidWork = !state.partnerPaidWork;
        return newState;
    }
    if (action.type === 'SET_PARTNER_EDUCATION') {
        newState = Object.assign({}, state);
        newState.partnerEducation = !state.partnerEducation;
        return newState;
    }
    if (action.type === 'SET_PARTNER_HIGHER_EDUCATION') {
        newState = Object.assign({}, state);
        newState.partnerHigherEducation = !state.partnerHigherEducation;
        return newState;
    }
    if (action.type === 'SET_PARTNER_COURSE_START') {
        newState = Object.assign({}, state);
        newState.partnerCourseStart = !state.partnerCourseStart;
        return newState;
    }
    if (action.type === 'SET_PARTNER_DISABLED') {
        newState = Object.assign({}, state);
        newState.partnerDisabled = !state.partnerDisabled;
        return newState;
    }
    if (action.type === 'SET_PARTNER_DLA') {
        newState = Object.assign({}, state);
        newState.partnerDLA = !state.partnerDLA;
        return newState;
    }
    if (action.type === 'SET_PARTNER_PIP') {
        newState = Object.assign({}, state);
        newState.partnerPIP = !state.partnerPIP;
        return newState;
    }
    if (action.type === 'SET_PARTNER_ATTENDANCE_ALLOWANCE') {
        newState = Object.assign({}, state);
        newState.partnerAttendanceAllowance = !state.partnerAttendanceAllowance;
        return newState;
    }
    if (action.type === 'SET_PARTNER_AFIP') {
        newState = Object.assign({}, state);
        newState.partnerAFIP = !state.partnerAFIP;
        return newState;
    }
    if (action.type === 'SET_PARTNER_SEVERE_DISABILITY_PREMIUM') {
        newState = Object.assign({}, state);
        newState.partnerSevereDisabilityPremium = !state.partnerSevereDisabilityPremium;
        return newState;
    }
    if (action.type === 'SET_PARTNER_PREGNANT') {
        newState = Object.assign({}, state);
        newState.partnerPregnant = !state.partnerPregnant;
        return newState;
    }

    // Dependant children
    if (action.type === 'SET_DEPENDANT_CHILDREN') {
        newState = Object.assign({}, state);
        newState.dependantChildren = !state.dependantChildren;
        return newState;
    }

    // Caring for others
    if (action.type === 'SET_CARING_FOR_OTHERS') {
        newState = Object.assign({}, state);
        newState.caringForOthers = !state.caringForOthers;
        return newState;
    }

    // Capability to work
    if (action.type === 'SET_LIMITED_CAPABILITY') {
        newState = Object.assign({}, state);
        newState.limitedCapability = !state.limitedCapability;
        return newState;
    }

    // Income
    if (action.type === 'SET_PARENTAL_SUPPORT') {
        newState = Object.assign({}, state);
        newState.parentalSupport = !state.parentalSupport;
        return newState;
    }

    // Capital
    if (action.type === 'SET_SAVINGS') {
        newState = Object.assign({}, state);
        newState.savings = action.payload === '' ? 0 : parseFloat(action.payload);
        return newState;
    }
    if (action.type === 'SET_INVESTMENTS') {
        newState = Object.assign({}, state);
        newState.investments = action.payload === '' ? 0 : parseFloat(action.payload);
        return newState;
    }
    if (action.type === 'SET_OWNED_PROPERTY') {
        newState = Object.assign({}, state);
        newState.ownedProperty = action.payload === '' ? 0 : parseFloat(action.payload);
        return newState;
    }
    if (action.type === 'SET_ASSET_STATE') {
        newState = Object.assign({}, state);
        newState.assetState = action.payload;
        return newState;
    }

    return state;
};

export default universalCreditEligibility;