const initialState = {
    // Step tracking
    currentStep: 1,

    // About you
    circumstance: 'singleUnder',
    paidWork: false,
    education: false,

    // About your partner
    partnerPaidWork: false,
    partnerEducation: false,

    // Children
    dependantChildren: false,
    childState: [{
        childAge: '',
        childBornBefore: false,
        childDisabled: false,
        childDisabilityEntitlement: 'DLA1',
        childBlind: false,
    }],

    // Childcare
    childcare: false,
    childcareState: [{
        childcareCost: '',
        childcarePer: 'Year',
    }],

    // Caring for others
    caringForOthers: false,
    careAmount: false,
    partnerCareAmount: false,
    careSamePerson: false,

    // Capability to work
    limitedCapability: false,
    limitedCapabilityActivity: false,

    // Income
    salary: '',
    studentLoan: '',
    partnerSalary: '',
    partnerStudentLoan: '',
    incomeState: [{
        incomeName: '',
		incomeValue: '',
    }],

    // Capital
    savings: '',
    investments: '',
    ownedProperty: '',
    assetState: [{
        assetName: '',
		assetValue: '',
    }],

    // Benefits
    benefits: false,
    carersAllowance: false,
    carersAllowanceAmount: '',
    incapacityBenefit: false,
    incapacityBenefitAmount: '',
    maternityAllowance: false,
    maternityAllowanceAmount: '',
    ESA: false,
    ESAAmount: '',
    JSA: false,
    JSAAmount: '',

    // Liability
    liabilityState: [{
        liabilityName: '',
		liabilityValue: '',
    }],
};

const uccStepTracker = (state = initialState, action) => {
    let newState;

    // Step tracking
    if (action.type === 'SET_UCCSTEPTRACKER_CURRENT_STEP') {
        newState = Object.assign({}, state);
        newState.currentStep = action.payload;
        return newState;
    }

    if (action.type === 'RESET_UCC_SELECTIONS') {
        return {...initialState};
    }

    // About you
    if (action.type === 'SET_CIRCUMSTANCE') {
        newState = Object.assign({}, state);
        newState.circumstance = action.payload;

        // Reset partner values
        if (action.payload === 'singleUnder' || action.payload === 'singleOver') {
            newState.partnerPaidWork = false;
            newState.partnerEducation = false;
            newState.partnerCareAmount = false;
            newState.careSamePerson = false;
            newState.partnerSalary = '';
            newState.partnerStudentLoan = '';
        }

        return newState;
    }

    if (action.type === 'SET_PAID_WORK') {
        newState = Object.assign({}, state);
        newState.paidWork = action.payload;
        return newState;
    }

    if (action.type === 'SET_EDUCATION') {
        newState = Object.assign({}, state);
        newState.education = action.payload;
        return newState;
    }

    if (action.type === 'SET_PARTNER_PAID_WORK') {
        newState = Object.assign({}, state);
        newState.partnerPaidWork = action.payload;
        return newState;
    }

    if (action.type === 'SET_PARTNER_EDUCATION') {
        newState = Object.assign({}, state);
        newState.partnerEducation = action.payload;
        return newState;
    }

    if (action.type === 'SET_DEPENDANT_CHILDREN') {
        newState = Object.assign({}, state);
        newState.dependantChildren = action.payload;

        if (!action.payload) {
            newState.childState = [{
                childAge: '',
                childBornBefore: false,
                childDisabled: false,
                childDisabilityEntitlement: 'DLA1',
                childBlind: false,
            }];
        }

        return newState;
    }

    if (action.type === 'SET_CHILD_STATE') {
        newState = Object.assign({}, state);
        newState.childState = action.payload;
        return newState;
    }

    if (action.type === 'SET_CHILDCARE') {
        newState = Object.assign({}, state);
        newState.childcare = action.payload;

        if (!action.payload) {
            newState.childcareState = [{
                childcareCost: '',
                childcarePer: 'Year',
            }];
        }

        return newState;
    }

    if (action.type === 'SET_CHILDCARE_STATE') {
        newState = Object.assign({}, state);
        newState.childcareState = action.payload;
        return newState;
    }

    if (action.type === 'SET_CARING_FOR_OTHERS') {
        newState = Object.assign({}, state);
        newState.caringForOthers = action.payload;

        if (!action.payload) {
            newState.careAmount = false;
            newState.partnerCareAmount = false;
            newState.careSamePerson = false;
        }

        return newState;
    }

    if (action.type === 'SET_CARE_AMOUNT') {
        newState = Object.assign({}, state);
        newState.careAmount = action.payload;

        if (!action.payload && state.partnerCareAmount) {
            newState.careSamePerson = false;
        }

        return newState;
    }

    if (action.type === 'SET_PARTNER_CARE_AMOUNT') {
        newState = Object.assign({}, state);
        newState.partnerCareAmount = action.payload;

        if (!action.payload && state.careAmount) {
            newState.careSamePerson = false;
        }

        return newState;
    }

    if (action.type === 'SET_CARE_SAME_PERSON') {
        newState = Object.assign({}, state);
        newState.careSamePerson = action.payload;
        return newState;
    }

    if (action.type === 'SET_LIMITED_CAPABILITY') {
        newState = Object.assign({}, state);
        newState.limitedCapability = action.payload;
        return newState;
    }

    if (action.type === 'SET_LIMITED_CAPABILITY_ACTIVITY') {
        newState = Object.assign({}, state);
        newState.limitedCapabilityActivity = action.payload;
        return newState;
    }

    if (action.type === 'SET_SALARY') {
        newState = Object.assign({}, state);
        newState.salary = action.payload;
        return newState;
    }

    if (action.type === 'SET_PARTNER_SALARY') {
        newState = Object.assign({}, state);
        newState.partnerSalary = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN') {
        newState = Object.assign({}, state);
        newState.studentLoan = action.payload;
        return newState;
    }

    if (action.type === 'SET_PARTNER_STUDENT_LOAN') {
        newState = Object.assign({}, state);
        newState.partnerStudentLoan = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_STATE') {
        newState = Object.assign({}, state);
        newState.incomeState = action.payload;
        return newState;
    }

    if (action.type === 'SET_SAVINGS') {
        newState = Object.assign({}, state);
        newState.savings = action.payload;
        return newState;
    }

    if (action.type === 'SET_INVESTMENTS') {
        newState = Object.assign({}, state);
        newState.investments = action.payload;
        return newState;
    }

    if (action.type === 'SET_OWNED_PROPERTY') {
        newState = Object.assign({}, state);
        newState.ownedProperty = action.payload;
        return newState;
    }

    if (action.type === 'SET_ASSET_STATE') {
        newState = Object.assign({}, state);
        newState.assetState = action.payload;
        return newState;
    }

    if (action.type === 'SET_BENEFITS') {
        newState = Object.assign({}, state);
        newState.benefits = action.payload;

        if (!action.payload) {
            newState.carersAllowance = false;
            newState.carersAllowanceAmount = '';
            newState.incapacityBenefit = false;
            newState.incapacityBenefitAmount = '';
            newState.maternityAllowance = false;
            newState.maternityAllowanceAmount = '';
            newState.ESA = false;
            newState.ESAAmount = '';
            newState.JSA = false;
            newState.JSAAmount = '';
        }

        return newState;
    }

    if (action.type === 'SET_CARERS_ALLOWANCE') {
        newState = Object.assign({}, state);
        newState.carersAllowance = action.payload;

        if (!action.payload) {
            newState.carersAllowanceAmount = '';
        }

        return newState;
    }

    if (action.type === 'SET_CARERS_ALLOWANCE_AMOUNT') {
        newState = Object.assign({}, state);
        newState.carersAllowanceAmount = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCAPACITY_BENEFIT') {
        newState = Object.assign({}, state);
        newState.incapacityBenefit = action.payload;

        if (!action.payload) {
            newState.incapacityBenefitAmount = '';
        }

        return newState;
    }

    if (action.type === 'SET_INCAPACITY_BENEFIT_AMOUNT') {
        newState = Object.assign({}, state);
        newState.incapacityBenefitAmount = action.payload;
        return newState;
    }

    if (action.type === 'SET_MATERNITY_ALLOWANCE') {
        newState = Object.assign({}, state);
        newState.maternityAllowance = action.payload;

        if (!action.payload) {
            newState.maternityAllowanceAmount = '';
        }

        return newState;
    }

    if (action.type === 'SET_MATERNITY_ALLOWANCE_AMOUNT') {
        newState = Object.assign({}, state);
        newState.maternityAllowanceAmount = action.payload;
        return newState;
    }

    if (action.type === 'SET_ESA') {
        newState = Object.assign({}, state);
        newState.ESA = action.payload;

        if (!action.payload) {
            newState.ESAAmount = '';
        }

        return newState;
    }

    if (action.type === 'SET_ESA_AMOUNT') {
        newState = Object.assign({}, state);
        newState.ESAAmount = action.payload;
        return newState;
    }

    if (action.type === 'SET_JSA') {
        newState = Object.assign({}, state);
        newState.JSA = action.payload;

        if (!action.payload) {
            newState.JSAAmount = '';
        }

        return newState;
    }

    if (action.type === 'SET_JSA_AMOUNT') {
        newState = Object.assign({}, state);
        newState.JSAAmount = action.payload;
        return newState;
    }

    if (action.type === 'SET_LIABILITY_STATE') {
        newState = Object.assign({}, state);
        newState.liabilityState = action.payload;
        return newState;
    }

    return state;
};

export default uccStepTracker;