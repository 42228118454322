import withShareResults from '../../helpers/with-share-results';

const initialState = {
    monthlyPayment: '',
    interest: '',
    period: '',
};

const mortgageAffordability = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_MORTGAGE_AFFORDABILITY_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_MORTGAGE_AFFORDABILITY_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.monthlyPayment = urlParams?.get('monthlyPayment') || state.monthlyPayment;
        newState.interest = urlParams?.get('interest') || state.interest;
        newState.period = urlParams?.get('period') || state.period;
        return newState;
    }

    if (action.type === 'SET_MORTGAGE_AFFORDABILITY_MONTHLY_PAYMENT') {
        newState = Object.assign({}, state);
        newState.monthlyPayment = action.payload;
        return newState;
    }

    if (action.type === 'SET_MORTGAGE_AFFORDABILITY_INTEREST') {
        newState = Object.assign({}, state);
        newState.interest = action.payload;
        return newState;
    }

    if (action.type === 'SET_MORTGAGE_AFFORDABILITY_PERIOD') {
        newState = Object.assign({}, state);
        newState.period = action.payload;
        return newState;
    }

    return state;
};

export default mortgageAffordability;