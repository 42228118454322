import withShareResults from '../../helpers/with-share-results';

const initialState = {
    salary: '',
    paidPer: 'year',
    bonus: '',
    bonusAsPercentage: false,
    pensionContribution: '',
    workingDays: 5,
    workingHours: 7,
    blind: false,
    mca: false,
    filterResults: 'year',
};

const incomeTax = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_INCOME_TAX_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_INCOME_TAX_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.salary = urlParams?.get('salary') || state.salary;
        newState.paidPer = urlParams?.get('paidPer') || state.paidPer;
        newState.bonus = urlParams?.get('bonus') || state.bonus;
        newState.bonusAsPercentage = urlParams?.get('bonusAsPercentage') || state.bonusAsPercentage;
        newState.pensionContribution = urlParams?.get('pensionContribution') || state.pensionContribution;
        newState.workingDays = urlParams?.get('workingDays') || state.workingDays;
        newState.workingHours = urlParams?.get('workingHours') || state.workingHours;
        newState.blind = urlParams?.get('blind') || state.blind;
        newState.mca = urlParams?.get('mca') || state.mca;
        newState.filterResults = urlParams?.get('filterResults') || state.filterResults;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_SALARY') {
        newState = Object.assign({}, state);
        newState.salary = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_PAID_PER') {
        newState = Object.assign({}, state);
        newState.paidPer = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_BONUS') {
        newState = Object.assign({}, state);
        newState.bonus = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_BONUS_AS_PERCENTAGE') {
        newState = Object.assign({}, state);
        newState.bonusAsPercentage = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_PENSION') {
        newState = Object.assign({}, state);
        newState.pensionContribution = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_WORKING_DAYS') {
        newState = Object.assign({}, state);
        newState.workingDays = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_WORKING_HOURS') {
        newState = Object.assign({}, state);
        newState.workingHours = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_BLIND') {
        newState = Object.assign({}, state);
        newState.blind = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_MCA') {
        newState = Object.assign({}, state);
        newState.mca = action.payload;
        return newState;
    }

    if (action.type === 'SET_INCOME_TAX_FILTER_RESULTS') {
        newState = Object.assign({}, state);
        newState.filterResults = action.payload;
        return newState;
    }

    return state;
};

export default incomeTax;