const withShareResults = () => {
  const windowExists = typeof window !== 'undefined';
  const urlWithoutParams = windowExists ? window.location.href.split('?')[0] : '';
  const urlParams = windowExists ? new URLSearchParams(window.location.search) : undefined;
  const selectedYearParam = urlParams?.get('selectedYear');
  const currentStepParam = urlParams?.get('currentStep');

  const createUrlParamsFromState = (state, selectedYear) => {
    let combinedState = {...state};

    if (selectedYear) {
        combinedState = {
            ...state,
            selectedYear: selectedYear['selectedYear'],
        };
    }

    const paramsWithValues = Object.keys(combinedState).filter(key => {
      const stateIsArray = Array.isArray(combinedState[key]);
      const itemHasValue = stateIsArray ? !!combinedState[key].length : combinedState[key];

      return itemHasValue;
    });

    const paramString = paramsWithValues.map(item => {
      const valueIsArray = Array.isArray(combinedState[item]);
      const stringValue = valueIsArray ? `${item}=${JSON.stringify(combinedState[item])}` : `${item}=${combinedState[item]}`;

      return stringValue;
    }).join('&');

    return `?${paramString}`;
  };

  const getShareLink = (query, figures) => {
    const paramString = createUrlParamsFromState(query, figures);
    const shareLink = `${urlWithoutParams}${paramString}`;
    const encodedLink = encodeURI(shareLink);
    
    return encodedLink;
  };

  // Functions to export
  const shareResultsActions = {
    windowExists,
    urlParams,
    selectedYearParam,
    currentStepParam,
    getShareLink,
  };

  return shareResultsActions;
}

export default withShareResults;