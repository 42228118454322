exports.components = {
  "component---src-layouts-article-listing-index-jsx": () => import("./../../../src/layouts/article-listing/index.jsx" /* webpackChunkName: "component---src-layouts-article-listing-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-tags-index-js": () => import("./../../../src/pages/articles/tags/index.js" /* webpackChunkName: "component---src-pages-articles-tags-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-child-benefit-js": () => import("./../../../src/pages/information/child-benefit.js" /* webpackChunkName: "component---src-pages-information-child-benefit-js" */),
  "component---src-pages-information-salary-sacrifice-js": () => import("./../../../src/pages/information/salary-sacrifice.js" /* webpackChunkName: "component---src-pages-information-salary-sacrifice-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-articles-tags-js": () => import("./../../../src/templates/articles/tags.js" /* webpackChunkName: "component---src-templates-articles-tags-js" */),
  "component---src-templates-benefits-child-benefit-js": () => import("./../../../src/templates/benefits/child-benefit.js" /* webpackChunkName: "component---src-templates-benefits-child-benefit-js" */),
  "component---src-templates-benefits-js": () => import("./../../../src/templates/benefits.js" /* webpackChunkName: "component---src-templates-benefits-js" */),
  "component---src-templates-benefits-universal-credit-payments-js": () => import("./../../../src/templates/benefits/universal-credit-payments.js" /* webpackChunkName: "component---src-templates-benefits-universal-credit-payments-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-personal-finance-dividend-tax-js": () => import("./../../../src/templates/personal-finance/dividend-tax.js" /* webpackChunkName: "component---src-templates-personal-finance-dividend-tax-js" */),
  "component---src-templates-personal-finance-income-tax-js": () => import("./../../../src/templates/personal-finance/income-tax.js" /* webpackChunkName: "component---src-templates-personal-finance-income-tax-js" */),
  "component---src-templates-personal-finance-inheritance-tax-js": () => import("./../../../src/templates/personal-finance/inheritance-tax.js" /* webpackChunkName: "component---src-templates-personal-finance-inheritance-tax-js" */),
  "component---src-templates-personal-finance-js": () => import("./../../../src/templates/personal-finance.js" /* webpackChunkName: "component---src-templates-personal-finance-js" */),
  "component---src-templates-personal-finance-national-insurance-js": () => import("./../../../src/templates/personal-finance/national-insurance.js" /* webpackChunkName: "component---src-templates-personal-finance-national-insurance-js" */),
  "component---src-templates-personal-finance-pension-contribution-js": () => import("./../../../src/templates/personal-finance/pension-contribution.js" /* webpackChunkName: "component---src-templates-personal-finance-pension-contribution-js" */),
  "component---src-templates-personal-finance-salary-js": () => import("./../../../src/templates/personal-finance/salary.js" /* webpackChunkName: "component---src-templates-personal-finance-salary-js" */),
  "component---src-templates-personal-finance-salary-sacrifice-js": () => import("./../../../src/templates/personal-finance/salary-sacrifice.js" /* webpackChunkName: "component---src-templates-personal-finance-salary-sacrifice-js" */),
  "component---src-templates-personal-finance-student-loan-repayment-js": () => import("./../../../src/templates/personal-finance/student-loan-repayment.js" /* webpackChunkName: "component---src-templates-personal-finance-student-loan-repayment-js" */),
  "component---src-templates-policies-cookie-policy-js": () => import("./../../../src/templates/policies/cookie-policy.js" /* webpackChunkName: "component---src-templates-policies-cookie-policy-js" */),
  "component---src-templates-policies-privacy-policy-js": () => import("./../../../src/templates/policies/privacy-policy.js" /* webpackChunkName: "component---src-templates-policies-privacy-policy-js" */),
  "component---src-templates-policies-terms-and-conditions-js": () => import("./../../../src/templates/policies/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-policies-terms-and-conditions-js" */),
  "component---src-templates-property-adjustable-rate-mortgage-js": () => import("./../../../src/templates/property/adjustable-rate-mortgage.js" /* webpackChunkName: "component---src-templates-property-adjustable-rate-mortgage-js" */),
  "component---src-templates-property-energy-performance-rating-js": () => import("./../../../src/templates/property/energy-performance-rating.js" /* webpackChunkName: "component---src-templates-property-energy-performance-rating-js" */),
  "component---src-templates-property-fixed-rate-mortgage-js": () => import("./../../../src/templates/property/fixed-rate-mortgage.js" /* webpackChunkName: "component---src-templates-property-fixed-rate-mortgage-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-templates-property-mortgage-affordability-js": () => import("./../../../src/templates/property/mortgage-affordability.js" /* webpackChunkName: "component---src-templates-property-mortgage-affordability-js" */),
  "component---src-templates-property-stamp-duty-js": () => import("./../../../src/templates/property/stamp-duty.js" /* webpackChunkName: "component---src-templates-property-stamp-duty-js" */)
}

