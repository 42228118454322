const initialState = {
    // Standard Amount
    singleUnder: 311.68,
    singleOver: 393.45,
    coupleUnder: 489.23,
    coupleOver: 617.60,

    // Child Amount
    firstChildBefore: 333.33,
    firstChildAfter: 287.92,
    secondChild: 287.92,

    // Disabled Children Amount
    disabledChildLowerRate: 156.11,
    disabledChildHigherRate: 487.58,

    // Childcare Amount
    childcareMaxSingle: 1014.63,
    childcareMaxMultiple: 1739.37,

    // Caring for others
    carersAmount: 198.31,

    // Capability to work
    limitedCapability: 156.11,
    limitedCapabilityActivity: 416.19,

    // Income
    workAllowanceLower: 404,
    workAllowanceHigher: 673,
    incomeDeductionRatio: 0.55,

    // Capital
    capitalAllowance: 6000,
    capitalHigherRate: 16000,
    capitalIncrement: 250,
    capitalMonthlyIncome: 4.35,

    // Liabilities
    liabilityMaxPercentage: 0.3,

    // State Pension Age
    statePensionAge: 66,
};

const uccFigures = (state = initialState) => {
    return state;
};

export default uccFigures;