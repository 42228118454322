import withShareResults from '../../helpers/with-share-results';

const initialState = {
    propertyValue: '',
    firstTimeBuyer: false,
    additionalProperty: false,
};

const stampDuty = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_STAMP_DUTY_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_STAMP_DUTY_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.propertyValue = urlParams?.get('propertyValue') || state.propertyValue;
        newState.firstTimeBuyer = urlParams?.get('firstTimeBuyer') || state.firstTimeBuyer;
        newState.additionalProperty = urlParams?.get('additionalProperty') || state.additionalProperty;
        return newState;
    }

    if (action.type === 'SET_STAMP_DUTY_PROPERTY_VALUE') {
        newState = Object.assign({}, state);
        newState.propertyValue = action.payload;
        return newState;
    }

    if (action.type === 'SET_STAMP_DUTY_FIRST_TIME_BUYER') {
        newState = Object.assign({}, state);
        newState.firstTimeBuyer = action.payload;
        return newState;
    }

    if (action.type === 'SET_STAMP_DUTY_ADDITIONAL_PROPERTY') {
        newState = Object.assign({}, state);
        newState.additionalProperty = action.payload;
        return newState;
    }

    return state;
};

export default stampDuty;