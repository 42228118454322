import withShareResults from '../../helpers/with-share-results';

const initialState = {
    qualifyingEarningsCap: 50270,
    qualifyingEarningsLowerThreshold: 6396,
    grossIncome: '',
    paidPer: 'year',
    yourContribution: '',
    employerContribution: '',
    partialContribution: false,
    filterResults: 'month',
};

const pensionContribution = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_PENSION_CONTRIBUTION_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_PENSION_CONTRIBUTION_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.grossIncome = urlParams?.get('grossIncome') || state.grossIncome;
        newState.paidPer = urlParams?.get('paidPer') || state.paidPer;
        newState.yourContribution = urlParams?.get('yourContribution') || state.yourContribution;
        newState.employerContribution = urlParams?.get('employerContribution') || state.employerContribution;
        newState.partialContribution = urlParams?.get('partialContribution') || state.partialContribution;
        newState.filterResults = urlParams?.get('filterResults') || state.filterResults;
        return newState;
    }

    if (action.type === 'SET_PENSION_CONTRIBUTION_GROSS_INCOME') {
        newState = Object.assign({}, state);
        newState.grossIncome = action.payload;
        return newState;
    }

    if (action.type === 'SET_PENSION_CONTRIBUTION_PAID_PER') {
        newState = Object.assign({}, state);
        newState.paidPer = action.payload;
        return newState;
    }

    if (action.type === 'SET_PENSION_CONTRIBUTION_YOURS') {
        newState = Object.assign({}, state);
        newState.yourContribution = action.payload;
        return newState;
    }

    if (action.type === 'SET_PENSION_CONTRIBUTION_EMPLOYER') {
        newState = Object.assign({}, state);
        newState.employerContribution = action.payload;
        return newState;
    }

    if (action.type === 'SET_PENSION_CONTRIBUTION_PARTIAL') {
        newState = Object.assign({}, state);
        newState.partialContribution = action.payload;
        return newState;
    }

    if (action.type === 'SET_PENSION_CONTRIBUTION_FILTER_RESULTS') {
        newState = Object.assign({}, state);
        newState.filterResults = action.payload;
        return newState;
    }

    return state;
};

export default pensionContribution;