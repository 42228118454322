import withShareResults from '../../helpers/with-share-results';

const initialState = {
    grossIncome: '',
    paidPer: 'year',
    employeeContribution: '',
    employeeContributionInPounds: false,
    employerContribution: '',
    employerContributionInPounds: false,
    percentageSacrificed: '',
    filterResults: 'year',
};

const salarySacrifice = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_SALARY_SACRIFICE_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_SALARY_SACRIFICE_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.grossIncome = urlParams?.get('grossIncome') || state.grossIncome;
        newState.paidPer = urlParams?.get('paidPer') || state.paidPer;
        newState.employeeContribution = urlParams?.get('employeeContribution') || state.employeeContribution;
        newState.employeeContributionInPounds = urlParams?.get('employeeContributionInPounds') || state.employeeContributionInPounds;
        newState.employerContribution = urlParams?.get('employerContribution') || state.employerContribution;
        newState.employerContributionInPounds = urlParams?.get('employerContributionInPounds') || state.employerContributionInPounds;
        newState.percentageSacrificed = urlParams?.get('percentageSacrificed') || state.percentageSacrificed;
        newState.filterResults = urlParams?.get('filterResults') || state.filterResults;
        return newState;
    }

    if (action.type === 'SET_SALARY_SACRIFICE_GROSS_INCOME') {
        newState = Object.assign({}, state);
        newState.grossIncome = action.payload;
        return newState;
    }

    if (action.type === 'SET_SALARY_SACRIFICE_PAID_PER') {
        newState = Object.assign({}, state);
        newState.paidPer = action.payload;
        return newState;
    }

    if (action.type === 'SET_SALARY_SACRIFICE_EMPLOYEE_CONTRIBUTION') {
        newState = Object.assign({}, state);
        newState.employeeContribution = action.payload;
        return newState;
    }

    if (action.type === 'SET_SALARY_SACRIFICE_EMPLOYER_CONTRIBUTION') {
        newState = Object.assign({}, state);
        newState.employerContribution = action.payload;
        return newState;
    }

    if (action.type === 'SET_TAKE_SALARY_SACRIFICE_EMPLOYEE_CONTRIBUTION_IN_POUNDS') {
        newState = Object.assign({}, state);
        newState.employeeContributionInPounds = action.payload;
        return newState;
    }

    if (action.type === 'SET_TAKE_SALARY_SACRIFICE_EMPLOYER_CONTRIBUTION_IN_POUNDS') {
        newState = Object.assign({}, state);
        newState.employerContributionInPounds = action.payload;
        return newState;
    }

    if (action.type === 'SET_SALARY_SACRIFICE_PERCENTAGE_SACRIFICED') {
        newState = Object.assign({}, state);
        newState.percentageSacrificed = action.payload;
        return newState;
    }

    if (action.type === 'SET_SALARY_SACRIFICE_FILTER_RESULTS') {
        newState = Object.assign({}, state);
        newState.filterResults = action.payload;
        return newState;
    }

    return state;
};

export default salarySacrifice;