const initialState = {
    numberChildren: '',
    yourIncome: '',
    partnerIncome: '',
    filterResults: 'year',
};

const childBenefit = (state = initialState, action) => {
    let newState;

    if (action.type === 'RESET_CHILD_BENEFIT_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'SET_CHILD_BENEFIT_NUMBER_CHILDREN') {
        newState = Object.assign({}, state);
        newState.numberChildren = action.payload;
        return newState;
    }

    if (action.type === 'SET_CHILD_BENEFIT_YOUR_INCOME') {
        newState = Object.assign({}, state);
        newState.yourIncome = action.payload;
        return newState;
    }

    if (action.type === 'SET_CHILD_BENEFIT_PARTNER_INCOME') {
        newState = Object.assign({}, state);
        newState.partnerIncome = action.payload;
        return newState;
    }

    if (action.type === 'SET_CHILD_BENEFIT_FILTER_RESULTS') {
        newState = Object.assign({}, state);
        newState.filterResults = action.payload;
        return newState;
    }

    return state;
};

export default childBenefit;