import withShareResults from '../../helpers/with-share-results';

const initialState = {
    amount: '',
    interest: '',
    period: '',
};

const fixedRateMortgage = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_FIXED_MORTGAGE_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_FIXED_MORTGAGE_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.amount = urlParams?.get('amount') || state.amount;
        newState.interest = urlParams?.get('interest') || state.interest;
        newState.period = urlParams?.get('period') || state.period;
        return newState;
    }

    if (action.type === 'SET_FIXED_MORTGAGE_AMOUNT') {
        newState = Object.assign({}, state);
        newState.amount = action.payload;
        return newState;
    }

    if (action.type === 'SET_FIXED_MORTGAGE_INTEREST') {
        newState = Object.assign({}, state);
        newState.interest = action.payload;
        return newState;
    }

    if (action.type === 'SET_FIXED_MORTGAGE_PERIOD') {
        newState = Object.assign({}, state);
        newState.period = action.payload;
        return newState;
    }

    return state;
};

export default fixedRateMortgage;