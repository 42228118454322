import withShareResults from '../../helpers/with-share-results';

const initialState = {
    grossIncome: '',
    paidPer: 'year',
    workingDays: 5,
    workingHours: 7,
    studentLoanPlan: 'Plan1',
    loanRemaining: '',
    studying: false,
    filterResults: 'year',
};

const studentLoanRepayment = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_STUDENT_LOAN_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_STUDENT_LOAN_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.grossIncome = urlParams?.get('grossIncome') || state.grossIncome;
        newState.paidPer = urlParams?.get('paidPer') || state.paidPer;
        newState.workingDays = urlParams?.get('workingDays') || state.workingDays;
        newState.workingHours = urlParams?.get('workingHours') || state.workingHours;
        newState.studentLoanPlan = urlParams?.get('studentLoanPlan') || state.studentLoanPlan;
        newState.loanRemaining = urlParams?.get('loanRemaining') || state.loanRemaining;
        newState.studying = urlParams?.get('studying') || state.studying;
        newState.filterResults = urlParams?.get('filterResults') || state.filterResults;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_GROSS_INCOME') {
        newState = Object.assign({}, state);
        newState.grossIncome = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_PAID_PER') {
        newState = Object.assign({}, state);
        newState.paidPer = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_WORKING_DAYS') {
        newState = Object.assign({}, state);
        newState.workingDays = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_WORKING_HOURS') {
        newState = Object.assign({}, state);
        newState.workingHours = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_PLAN_TYPE') {
        newState = Object.assign({}, state);
        newState.studentLoanPlan = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_REMAINING_DEBT') {
        newState = Object.assign({}, state);
        newState.loanRemaining = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_STILL_STUDYING') {
        newState = Object.assign({}, state);
        newState.studying = action.payload;
        return newState;
    }

    if (action.type === 'SET_STUDENT_LOAN_FILTER_RESULTS') {
        newState = Object.assign({}, state);
        newState.filterResults = action.payload;
        return newState;
    }

    return state;
};

export default studentLoanRepayment;