import withShareResults from '../../helpers/with-share-results';

const initialState = {
    salary: '',
    paidPer: 'year',
    bonus: '',
    bonusAsPercentage: false,
    workingDays: 5,
    workingHours: 7,
    selfEmployed: false,
    filterResults: 'year',
};

const nationalInsurance = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_NATIONAL_INSURANCE_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_NATIONAL_INSURANCE_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.salary = urlParams?.get('salary') || state.salary;
        newState.paidPer = urlParams?.get('paidPer') || state.paidPer;
        newState.bonus = urlParams?.get('bonus') || state.bonus;
        newState.bonusAsPercentage = urlParams?.get('bonusAsPercentage') || state.bonusAsPercentage;
        newState.workingDays = urlParams?.get('workingDays') || state.workingDays;
        newState.workingHours = urlParams?.get('workingHours') || state.workingHours;
        newState.selfEmployed = urlParams?.get('selfEmployed') || state.selfEmployed;
        newState.filterResults = urlParams?.get('filterResults') || state.filterResults;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_SALARY') {
        newState = Object.assign({}, state);
        newState.salary = action.payload;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_BONUS') {
        newState = Object.assign({}, state);
        newState.bonus = action.payload;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_BONUS_AS_PERCENTAGE') {
        newState = Object.assign({}, state);
        newState.bonusAsPercentage = action.payload;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_PAID_PER') {
        newState = Object.assign({}, state);
        newState.paidPer = action.payload;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_WORKING_DAYS') {
        newState = Object.assign({}, state);
        newState.workingDays = action.payload;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_WORKING_HOURS') {
        newState = Object.assign({}, state);
        newState.workingHours = action.payload;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_SELF_EMPLOYED') {
        newState = Object.assign({}, state);
        newState.selfEmployed = action.payload;
        return newState;
    }

    if (action.type === 'SET_NATIONAL_INSURANCE_FILTER_RESULTS') {
        newState = Object.assign({}, state);
        newState.filterResults = action.payload;
        return newState;
    }

    return state;
};

export default nationalInsurance;