// RESOURCES
// https://www.thepfs.org/learning-index/articles/the-spring-2020-budget-the-main-changes/91276
// https://www.gov.uk/government/publications/budget-2021-overview-of-tax-legislation-and-rates-ootlar/annex-a-rates-and-allowances
const initialState = {
  selectedYear: "2024",

  // Personal allowance
  personalAllowance: 12570,
  personalAllowanceReducerAllowance: 100000,
  blindAllowance: 3070,

  // MCA allowance
  mcaIncomeThreshold: 37000,
  mcaMaxAllowance: 11080,
  mcaMinAllowance: 428,

  // Income tax
  basicRateTax: 0.2,
  higherRateTax: 0.4,
  additionalRateTax: 0.45,
  basicRateBand: 50270,
  higherRateBand: 125140,

  // National Insurance
  niThreshold: 12570,
  niSecondaryThreshold: 9100,
  niHigherBand: 50270,
  niClass2Threshold: 6725,
  niBasicRate: 0.08,
  niHigherRate: 0.02,
  niClass2LowerRate: 3.45,
  niClass2BasicRate: 0.06,
  niClass2HigherRate: 0.02,
  niRateAboveSecondaryThreshold: 0.138,

  // Student Loan
  // Plan 1
  plan1Threshold: 24990,
  plan1RepaymentRate: 0.09,
  plan1InterestRate: 0.0625,

  // Plan 2
  plan2Threshold: 27295,
  plan2HigherThreshold: 49130,
  plan2RepaymentRate: 0.09,
  plan2StudyingInterestRate: 0.077,
  plan2BasicInterestRate: 0.077,
  plan2AdditionalInterestRate: 0.077,

  // Postgraduate (Plan 3)
  postgraduateThreshold: 21000,
  postgraduateRepaymentRate: 0.06,
  postgraduateInterestRate: 0.077,

  // Plan 4
  plan4Threshold: 31395,
  plan4RepaymentRate: 0.09,
  plan4InterestRate: 0.0625,

  // Plan 5
  plan5Threshold: 25000,
  plan5RepaymentRate: 0.09,
  plan5InterestRate: 0.077, // RPI %

  // Dividends
  dividendAllowance: 500,
  dividendBasicRate: 0.0875,
  dividendHigherRate: 0.3375,
  dividendAdditionalRate: 0.3935,

  // Inheritance Tax
  inheritanceThreshold: 325000,
  inheritanceDescendantsAllowance: 175000,
  inheritanceTaxRate: 0.4,
  inheritanceCharityTaxRate: 0.36,
  inheritanceTaperThreshold: 2000000,

  // Child Benefit
  childWeeklyBenefit: 25.60,
  childAdditionalBenefit: 16.95,
  childBenefitThreshold: 60000,
  childBenefitUpperLimit: 80000,
  childBenefitTaxRate: 200,
};

const yearlyFigures = (state = initialState, action) => {
  let newState;

  if (action.type === "RESET_YEAR") {
    return { ...initialState };
  }

  if (action.type === "SET_YEAR") {
    newState = Object.assign({}, state);
    newState.selectedYear = action.payload;

    if (newState.selectedYear === "2024") {
      newState = { ...initialState };
    } else if (newState.selectedYear === "2023") {
      // Personal allowance
      newState.personalAllowance = 12570;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2870;

      // MCA allowance
      newState.mcaIncomeThreshold = 34600;
      newState.mcaMaxAllowance = 10375;
      newState.mcaMinAllowance = 401;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 50270;
      newState.higherRateBand = 125140;

      // National Insurance
      newState.niThreshold = 12570;
      newState.niSecondaryThreshold = 9100;
      newState.niHigherBand = 50270;
      newState.niClass2Threshold = 6725;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 3.45;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 22015;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.05;

      // Plan 2
      newState.plan2Threshold = 27295;
      newState.plan2HigherThreshold = 49130;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.073;
      newState.plan2BasicInterestRate = 0.073;
      newState.plan2AdditionalInterestRate = 0.073;

      // Postgraduate (Plan 3)
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.073;

      // Plan 4
      newState.plan4Threshold = 27660;
      newState.plan4RepaymentRate = 0.09;
      newState.plan4InterestRate = 0.073;

      // Plan 5
      newState.plan5Threshold = 25000;
      newState.plan5RepaymentRate = 0.09;
      newState.plan5InterestRate = 0.089; // RPI %

      // Dividends
      newState.dividendAllowance = 1000;
      newState.dividendBasicRate = 0.0875;
      newState.dividendHigherRate = 0.3375;
      newState.dividendAdditionalRate = 0.3935;

      // Inheritance Tax
      newState.inheritanceThreshold = 325000;
      newState.inheritanceDescendantsAllowance = 175000;
      newState.inheritanceTaxRate = 0.4;
      newState.inheritanceCharityTaxRate = 0.36;
      newState.inheritanceTaperThreshold = 2000000;

      // Child Benefit
      newState.childWeeklyBenefit = 24;
      newState.childAdditionalBenefit = 15.90;
      newState.childBenefitThreshold = 50000;
      newState.childBenefitUpperLimit = 60000;
      newState.childBenefitTaxRate = 100;
    } else if (newState.selectedYear === "2022") {
      // Personal allowance
      newState.personalAllowance = 12570;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2600;

      // MCA allowance
      newState.mcaIncomeThreshold = 31400;
      newState.mcaMaxAllowance = 9415;
      newState.mcaMinAllowance = 364;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 50270;
      newState.higherRateBand = 150000;

      // National Insurance
      newState.niThreshold = 12570;
      newState.niSecondaryThreshold = 9100;
      newState.niHigherBand = 50270;
      newState.niClass2Threshold = 6725;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 3.15;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 20195;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.011;

      // Plan 2
      newState.plan2Threshold = 27295;
      newState.plan2HigherThreshold = 49130;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.065;
      newState.plan2BasicInterestRate = 0.065;
      newState.plan2AdditionalInterestRate = 0.065;

      // Postgraduate (Plan 3)
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.065;

      // Plan 4
      newState.plan4Threshold = 25375;
      newState.plan4RepaymentRate = 0.09;
      newState.plan4InterestRate = 0.0125;

      // Dividends
      newState.dividendAllowance = 2000;
      newState.dividendBasicRate = 0.0875;
      newState.dividendHigherRate = 0.3375;
      newState.dividendAdditionalRate = 0.3935;

      // Inheritance Tax
      newState.inheritanceThreshold = 325000;
      newState.inheritanceDescendantsAllowance = 175000;
      newState.inheritanceTaxRate = 0.4;
      newState.inheritanceCharityTaxRate = 0.36;
      newState.inheritanceTaperThreshold = 2000000;

      // Child Benefit
      newState.childWeeklyBenefit = 21.8;
      newState.childAdditionalBenefit = 14.45;
      newState.childBenefitThreshold = 50000;
      newState.childBenefitUpperLimit = 60000;
      newState.childBenefitTaxRate = 100;
    } else if (newState.selectedYear === "2021") {
      // Personal allowance
      newState.personalAllowance = 12570;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2520;

      // MCA allowance
      newState.mcaIncomeThreshold = 30400;
      newState.mcaMaxAllowance = 9125;
      newState.mcaMinAllowance = 353;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 50270;
      newState.higherRateBand = 150000;

      // National Insurance
      newState.niThreshold = 9568;
      newState.niSecondaryThreshold = 8840;
      newState.niHigherBand = 50270;
      newState.niClass2Threshold = 6515;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 3.05;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 19895;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.011;

      // Plan 2
      newState.plan2Threshold = 27295;
      newState.plan2HigherThreshold = 49130;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.056;
      newState.plan2BasicInterestRate = 0.026;
      newState.plan2AdditionalInterestRate = 0.056;

      // Postgraduate (Plan 3)
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.056;

      // Plan 4
      newState.plan4Threshold = 25000;
      newState.plan4RepaymentRate = 0.09;
      newState.plan4InterestRate = 0.011;

      // Dividends
      newState.dividendAllowance = 2000;
      newState.dividendBasicRate = 0.075;
      newState.dividendHigherRate = 0.325;
      newState.dividendAdditionalRate = 0.381;

      // Inheritance Tax
      newState.inheritanceThreshold = 325000;
      newState.inheritanceDescendantsAllowance = 175000;
      newState.inheritanceTaxRate = 0.4;
      newState.inheritanceCharityTaxRate = 0.36;
      newState.inheritanceTaperThreshold = 2000000;

      // Child Benefit
      newState.childWeeklyBenefit = 21.15;
      newState.childAdditionalBenefit = 14.0;
      newState.childBenefitThreshold = 50000;
      newState.childBenefitUpperLimit = 60000;
      newState.childBenefitTaxRate = 100;
    } else if (newState.selectedYear === "2020") {
      // Personal allowance
      newState.personalAllowance = 12500;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2500;

      // MCA allowance
      newState.mcaIncomeThreshold = 30200;
      newState.mcaMaxAllowance = 9075;
      newState.mcaMinAllowance = 351;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 50000;
      newState.higherRateBand = 150000;

      // National Insurance
      newState.niThreshold = 9500;
      newState.niSecondaryThreshold = 8788;
      newState.niHigherBand = 50000;
      newState.niClass2Threshold = 6475;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 3.05;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 19390;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.0175;

      // Plan 2
      newState.plan2Threshold = 26575;
      newState.plan2HigherThreshold = 47835;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.054;
      newState.plan2BasicInterestRate = 0.024;
      newState.plan2AdditionalInterestRate = 0.054;

      // Postgraduate
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.054;

      // Dividends
      newState.dividendAllowance = 2000;
      newState.dividendBasicRate = 0.075;
      newState.dividendHigherRate = 0.325;
      newState.dividendAdditionalRate = 0.381;

      // Inheritance Tax
      newState.inheritanceThreshold = 325000;
      newState.inheritanceDescendantsAllowance = 175000;
      newState.inheritanceTaxRate = 0.4;
      newState.inheritanceCharityTaxRate = 0.36;
      newState.inheritanceTaperThreshold = 2000000;

      // Child Benefit
      newState.childWeeklyBenefit = 21.05;
      newState.childAdditionalBenefit = 13.95;
      newState.childBenefitThreshold = 50000;
      newState.childBenefitUpperLimit = 60000;
      newState.childBenefitTaxRate = 100;
    } else if (newState.selectedYear === "2019") {
      // Personal allowance
      newState.personalAllowance = 12500;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2450;

      // MCA allowance
      newState.mcaIncomeThreshold = 29600;
      newState.mcaMaxAllowance = 8915;
      newState.mcaMinAllowance = 345;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 50000;
      newState.higherRateBand = 150000;

      // National Insurance
      newState.niThreshold = 8632;
      newState.niSecondaryThreshold = 8632;
      newState.niHigherBand = 50000;
      newState.niClass2Threshold = 6365;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 3;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 18935;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.0175;

      // Plan 2
      newState.plan2Threshold = 25725;
      newState.plan2HigherThreshold = 46305;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.054;
      newState.plan2BasicInterestRate = 0.024;
      newState.plan2AdditionalInterestRate = 0.054;

      // Postgraduate
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.054;

      // Dividends
      newState.dividendAllowance = 2000;
      newState.dividendBasicRate = 0.075;
      newState.dividendHigherRate = 0.325;
      newState.dividendAdditionalRate = 0.381;

      // Inheritance Tax
      newState.inheritanceThreshold = 325000;
      newState.inheritanceDescendantsAllowance = 150000;
      newState.inheritanceTaxRate = 0.4;
      newState.inheritanceCharityTaxRate = 0.36;
      newState.inheritanceTaperThreshold = 2000000;

      // Child Benefit
      newState.childWeeklyBenefit = 20.7;
      newState.childAdditionalBenefit = 13.7;
      newState.childBenefitThreshold = 50000;
      newState.childBenefitUpperLimit = 60000;
      newState.childBenefitTaxRate = 100;
    } else if (newState.selectedYear === "2018") {
      // Personal allowance
      newState.personalAllowance = 11850;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2390;

      // MCA allowance
      newState.mcaIncomeThreshold = 28900;
      newState.mcaMaxAllowance = 8695;
      newState.mcaMinAllowance = 336;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 46350;
      newState.higherRateBand = 150000;

      // National Insurance
      newState.niThreshold = 8424;
      newState.niSecondaryThreshold = 8424;
      newState.niHigherBand = 46350;
      newState.niClass2Threshold = 6205;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 2.95;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 18330;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.0175;

      // Plan 2
      newState.plan2Threshold = 25000;
      newState.plan2HigherThreshold = 45000;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.063;
      newState.plan2BasicInterestRate = 0.033;
      newState.plan2AdditionalInterestRate = 0.063;

      // Postgraduate
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.063;

      // Dividends
      newState.dividendAllowance = 2000;
      newState.dividendBasicRate = 0.075;
      newState.dividendHigherRate = 0.325;
      newState.dividendAdditionalRate = 0.381;

      // Inheritance Tax
      newState.inheritanceThreshold = 325000;
      newState.inheritanceDescendantsAllowance = 125000;
      newState.inheritanceTaxRate = 0.4;
      newState.inheritanceCharityTaxRate = 0.36;
      newState.inheritanceTaperThreshold = 2000000;

      // Child Benefit
      newState.childWeeklyBenefit = 20.7;
      newState.childAdditionalBenefit = 13.7;
      newState.childBenefitThreshold = 50000;
      newState.childBenefitUpperLimit = 60000;
      newState.childBenefitTaxRate = 100;
    } else if (newState.selectedYear === "2017") {
      // Personal allowance
      newState.personalAllowance = 11500;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2320;

      // MCA allowance
      newState.mcaIncomeThreshold = 28000;
      newState.mcaMaxAllowance = 8445;
      newState.mcaMinAllowance = 326;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 45000;
      newState.higherRateBand = 150000;

      // National Insurance
      newState.niThreshold = 8164;
      newState.niSecondaryThreshold = 8164;
      newState.niHigherBand = 45000;
      newState.niClass2Threshold = 6025;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 2.85;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 17775;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.015;

      // Plan 2
      newState.plan2Threshold = 21000;
      newState.plan2HigherThreshold = 41000;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.061;
      newState.plan2BasicInterestRate = 0.031;
      newState.plan2AdditionalInterestRate = 0.061;

      // Postgraduate
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.061;

      // Dividends
      newState.dividendAllowance = 5000;
      newState.dividendBasicRate = 0.075;
      newState.dividendHigherRate = 0.325;
      newState.dividendAdditionalRate = 0.381;

      // Inheritance Tax
      newState.inheritanceThreshold = 325000;
      newState.inheritanceDescendantsAllowance = 100000;
      newState.inheritanceTaxRate = 0.4;
      newState.inheritanceCharityTaxRate = 0.36;
      newState.inheritanceTaperThreshold = 2000000;
    } else if (newState.selectedYear === "2016") {
      // Personal allowance
      newState.personalAllowance = 11000;
      newState.personalAllowanceReducerAllowance = 100000;
      newState.blindAllowance = 2290;

      // MCA allowance
      newState.mcaIncomeThreshold = 27700;
      newState.mcaMaxAllowance = 8355;
      newState.mcaMinAllowance = 322;

      // Income tax
      newState.basicRateTax = 0.2;
      newState.higherRateTax = 0.4;
      newState.additionalRateTax = 0.45;
      newState.basicRateBand = 43000;
      newState.higherRateBand = 150000;

      // National Insurance
      newState.niThreshold = 8060;
      newState.niSecondaryThreshold = 8060;
      newState.niHigherBand = 43000;
      newState.niClass2Threshold = 5965;
      newState.niBasicRate = 0.12;
      newState.niHigherRate = 0.02;
      newState.niClass2LowerRate = 2.8;
      newState.niClass2BasicRate = 0.09;
      newState.niClass2HigherRate = 0.02;
      newState.niRateAboveSecondaryThreshold = 0.138;

      // Student Loan
      // Plan 1
      newState.plan1Threshold = 17495;
      newState.plan1RepaymentRate = 0.09;
      newState.plan1InterestRate = 0.0125;

      // Plan 2
      newState.plan2Threshold = 21000;
      newState.plan2HigherThreshold = 41000;
      newState.plan2RepaymentRate = 0.09;
      newState.plan2StudyingInterestRate = 0.046;
      newState.plan2BasicInterestRate = 0.016;
      newState.plan2AdditionalInterestRate = 0.046;

      // Postgraduate
      newState.postgraduateThreshold = 21000;
      newState.postgraduateRepaymentRate = 0.06;
      newState.postgraduateInterestRate = 0.046;

      // Dividends
      newState.dividendAllowance = 5000;
      newState.dividendBasicRate = 0.075;
      newState.dividendHigherRate = 0.325;
      newState.dividendAdditionalRate = 0.381;
      newState.inheritanceTaperThreshold = 2000000;
    }

    return newState;
  }

  return state;
};

export default yearlyFigures;
