import withShareResults from '../../helpers/with-share-results';

const initialState = {
    // Step tracking
    currentStep: 1,

    // Property
    mainPropertyValue: '',
    mainPropertyMortgage: '',
    propertyState: [{
        propertyValue: '',
        propertyMortgage: '',
    }],

    // Assets
    cash: '',
    investments: '',
    lifeInsurance: '',
    assetState: [{
		assetName: '',
		assetValue: '',
    }],
    
    // Liabilities
    liabilityState: [{
		liabilityName: '',
		liabilityValue: '',
	}],

    // Other info
    charityDonation: '',
    charityDonationAsPercentage: false,
    directDescendant: false,
};

const inheritanceTax = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    // Step tracking
    if (action.type === 'SET_INHERITANCETAX_CURRENT_STEP') {
        newState = Object.assign({}, state);
        newState.currentStep = action.payload;
        return newState;
    }

    if (action.type === 'RESET_INHERITANCE_TAX') {
        return {...initialState};
    }

    if (action.type === 'APPLY_INHERITANCE_TAX_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.currentStep = urlParams?.get('currentStep') || state.currentStep;
        newState.mainPropertyValue = urlParams?.get('mainPropertyValue') || state.mainPropertyValue;
        newState.mainPropertyMortgage = urlParams?.get('mainPropertyMortgage') || state.mainPropertyMortgage;
        newState.propertyState = urlParams?.get('propertyState') ? JSON.parse(urlParams?.get('propertyState')) : state.propertyState;
        newState.cash = urlParams?.get('cash') || state.cash;
        newState.investments = urlParams?.get('investments') || state.investments;
        newState.lifeInsurance = urlParams?.get('lifeInsurance') || state.lifeInsurance;
        newState.assetState = urlParams?.get('assetState') ? JSON.parse(urlParams?.get('assetState')) : state.assetState;
        newState.liabilityState = urlParams?.get('liabilityState') ? JSON.parse(urlParams?.get('liabilityState')) : state.liabilityState;
        newState.charityDonation = urlParams?.get('charityDonation') || state.charityDonation;
        newState.charityDonationAsPercentage = urlParams?.get('charityDonationAsPercentage') || state.charityDonationAsPercentage;
        newState.directDescendant = urlParams?.get('directDescendant') || state.directDescendant;
        return newState;
    }

    // Property
    if (action.type === 'SET_MAIN_PROPERTY_VALUE') {
        newState = Object.assign({}, state);
        newState.mainPropertyValue = action.payload;
        return newState;
    }

    if (action.type === 'SET_MAIN_PROPERTY_MORTGAGE') {
        newState = Object.assign({}, state);
        newState.mainPropertyMortgage = action.payload;
        return newState;
    }

    if (action.type === 'SET_PROPERTY_STATE') {
        newState = Object.assign({}, state);
        newState.propertyState = action.payload;
        return newState;
    }

    // Assets
    if (action.type === 'SET_CASH') {
        newState = Object.assign({}, state);
        newState.cash = action.payload;
        return newState;
    }

    if (action.type === 'SET_INVESTMENTS') {
        newState = Object.assign({}, state);
        newState.investments = action.payload;
        return newState;
    }
    
    if (action.type === 'SET_LIFE_INSURANCE') {
        newState = Object.assign({}, state);
        newState.lifeInsurance = action.payload;
        return newState;
    }

    if (action.type === 'SET_ASSET_STATE') {
        newState = Object.assign({}, state);
        newState.assetState = action.payload;
        return newState;
    }
    
    // Liabilities
    if (action.type === 'SET_LIABILITY_STATE') {
        newState = Object.assign({}, state);
        newState.liabilityState = action.payload;
        return newState;
    }

    // Other Information
    if (action.type === 'SET_CHARITY_DONATION') {
        newState = Object.assign({}, state);
        newState.charityDonation = action.payload;
        return newState;
    }

    if (action.type === 'SET_CHARITY_DONATION_AS_PERCENTAGE') {
        newState = Object.assign({}, state);
        newState.charityDonationAsPercentage = action.payload;
        return newState;
    }

    if (action.type === 'SET_DIRECT_DESCENDANT') {
        newState = Object.assign({}, state);
        newState.directDescendant = action.payload;
        return newState;
    }

    return state;
};

export default inheritanceTax;