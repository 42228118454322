import withShareResults from '../../helpers/with-share-results';

const initialState = {
    grossIncome: '',
    dividend: '',
    paidPer: 'year',
    filterResults: 'year',
    workingDays: 5,
    workingHours: 7,
    blind: false,
};

const dividendTax = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_DIVIDEND_TAX_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_DIVIDEND_TAX_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.grossIncome = urlParams?.get('grossIncome') || state.grossIncome;
        newState.dividend = urlParams?.get('dividend') || state.dividend;
        newState.paidPer = urlParams?.get('paidPer') || state.paidPer;
        newState.filterResults = urlParams?.get('filterResults') || state.filterResults;
        newState.workingDays = urlParams?.get('workingDays') || state.workingDays;
        newState.workingHours = urlParams?.get('workingHours') || state.workingHours;
        newState.blind = urlParams?.get('blind') || state.blind;
        return newState;
    }

    if (action.type === 'SET_DIVIDEND_TAX_GROSS_INCOME') {
        newState = Object.assign({}, state);
        newState.grossIncome = action.payload;
        return newState;
    }

    if (action.type === 'SET_DIVIDEND_TAX_DIVIDEND') {
        newState = Object.assign({}, state);
        newState.dividend = action.payload;
        return newState;
    }

    if (action.type === 'SET_DIVIDEND_TAX_PAID_PER') {
        newState = Object.assign({}, state);
        newState.paidPer = action.payload;
        return newState;
    }

    if (action.type === 'SET_DIVIDEND_TAX_FILTER_RESULTS') {
        newState = Object.assign({}, state);
        newState.filterResults = action.payload;
        return newState;
    }

    if (action.type === 'SET_DIVIDEND_TAX_WORKING_DAYS') {
        newState = Object.assign({}, state);
        newState.workingDays = action.payload;
        return newState;
    }

    if (action.type === 'SET_DIVIDEND_TAX_WORKING_HOURS') {
        newState = Object.assign({}, state);
        newState.workingHours = action.payload;
        return newState;
    }

    if (action.type === 'SET_DIVIDEND_TAX_BLIND') {
        newState = Object.assign({}, state);
        newState.blind = action.payload;
        return newState;
    }

    return state;
};

export default dividendTax;