const initialState = {
    // Step tracking
    currentStep: 1,

    // Tool state
    address: '',
    postcode: '',
    propertyType: [
        { name: 'bungalow', selected: false },
        { name: 'flat', selected: false },
        { name: 'house', selected: false },
        { name: 'maisonette', selected: false },
        { name: 'park-home', selected: false },
    ],
    energyRating: [
        { name: 'A', range: '92+', selected: false },
        { name: 'B', range: '81-91', selected: false },
        { name: 'C', range: '69-80', selected: false },
        { name: 'D', range: '55-68', selected: false },
        { name: 'E', range: '39-54', selected: false },
        { name: 'F', range: '21-38', selected: false },
        { name: 'G', range: '1-20', selected: false },
    ],
    fromDate: new Date('2008-01-01T00:00:00'),
    toDate: new Date(`${new Date().getFullYear()}-12-01T00:00:00`),
    data: {},
    recommendations: {},
    dataLoading: false,
    recommendationsLoading: true,
    selectedPropertyIndex: 0,
    selectedPropertyKey: '',
    isDomestic: true,
};

const energyPerformance = (state = initialState, action) => {
    let newState;

    // Step tracking
    if (action.type === 'SET_ENERGYPERFORMANCE_CURRENT_STEP') {
        newState = Object.assign({}, state);
        newState.currentStep = action.payload;
        return newState;
    }

    if (action.type === 'RESET_EPC_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'SET_EPC_ADDRESS') {
        newState = Object.assign({}, state);
        newState.address = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_POSTCODE') {
        newState = Object.assign({}, state);
        newState.postcode = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_PROPERTY_TYPE') {
        newState = Object.assign({}, state);
        newState.propertyType = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_ENERGY_RATING') {
        newState = Object.assign({}, state);
        newState.energyRating = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_FROM_DATE') {
        newState = Object.assign({}, state);
        newState.fromDate = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_TO_DATE') {
        newState = Object.assign({}, state);
        newState.toDate = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_RESPONSE_DATA') {
        newState = Object.assign({}, state);
        newState.data = action.payload;
        newState.dataLoading = false;
        return newState;
    }

    if (action.type === 'SET_EPC_RECOMMENDATIONS_DATA') {
        newState = Object.assign({}, state);
        newState.recommendations = action.payload;
        newState.recommendationsLoading = false;
        return newState;
    }

    if (action.type === 'SET_EPC_LOADING') {
        newState = Object.assign({}, state);
        newState.dataLoading = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_RECOMMENDATIONS_LOADING') {
        newState = Object.assign({}, state);
        newState.recommendationsLoading = action.payload;
        return newState;
    }

    if (action.type === 'SET_EPC_SELECTED_PROPERTY') {
        newState = Object.assign({}, state);
        newState.selectedPropertyIndex = action.index;
        newState.selectedPropertyKey = action.key;
        return newState;
    }

    if (action.type === 'SET_EPC_IS_DOMESTIC') {
        newState = Object.assign({}, state);
        newState.isDomestic = action.payload;
        return newState;
    }

    return state;
};

export default energyPerformance;