import withShareResults from '../../helpers/with-share-results';

const initialState = {
    amount: '',
	period: '',
	initialInterest: '',
	periodBeforeAdjustment: '',
	adjustedInterest: '',
	periodMonths: false,
};

const adjustableRateMortgage = (state = initialState, action) => {
    const { urlParams } = withShareResults();
    let newState;

    if (action.type === 'RESET_ADJUSTABLE_MORTGAGE_SELECTIONS') {
        return {...initialState};
    }

    if (action.type === 'APPLY_ADJUSTABLE_MORTGAGE_URL_PARAMS') {
        newState = Object.assign({}, state);
        newState.amount = urlParams?.get('amount') || state.amount;
        newState.period = urlParams?.get('period') || state.period;
        newState.initialInterest = urlParams?.get('initialInterest') || state.initialInterest;
        newState.periodBeforeAdjustment = urlParams?.get('periodBeforeAdjustment') || state.periodBeforeAdjustment;
        newState.adjustedInterest = urlParams?.get('adjustedInterest') || state.adjustedInterest;
        newState.periodMonths = urlParams?.get('periodMonths') || state.periodMonths;
        return newState;
    }

    if (action.type === 'SET_ADJUSTABLE_MORTGAGE_AMOUNT') {
        newState = Object.assign({}, state);
        newState.amount = action.payload;
        return newState;
    }

    if (action.type === 'SET_ADJUSTABLE_MORTGAGE_PERIOD') {
        newState = Object.assign({}, state);
        newState.period = action.payload;
        return newState;
    }

    if (action.type === 'SET_ADJUSTABLE_MORTGAGE_INITIAL_INTEREST') {
        newState = Object.assign({}, state);
        newState.initialInterest = action.payload;
        return newState;
    }

    if (action.type === 'SET_ADJUSTABLE_MORTGAGE_PERIOD_BEFORE_ADJUSTMENT') {
        newState = Object.assign({}, state);
        newState.periodBeforeAdjustment = action.payload;
        return newState;
    }

    if (action.type === 'SET_ADJUSTABLE_MORTGAGE_ADJUSTED_INTEREST') {
        newState = Object.assign({}, state);
        newState.adjustedInterest = action.payload;
        return newState;
    }

    if (action.type === 'SET_ADJUSTABLE_MORTGAGE_PERIOD_MONTHS') {
        newState = Object.assign({}, state);
        newState.periodMonths = action.payload;
        return newState;
    }

    return state;
};

export default adjustableRateMortgage;