import { combineReducers } from 'redux';

// Import reducers

// General
import yearlyFigures from './yearly-figures';

// Personal finance
import inheritanceTax from './inheritance-tax';
import salary from './salary';
import pensionContribution from './pension-contribution';
import studentLoanRepayment from './student-loan-repayment';
import nationalInsurance from './national-insurance';
import incomeTax from './income-tax';
import dividendTax from './dividend-tax';
import salarySacrifice from './salary-sacrifice';

// Property
import energyPerformance from './energy-performance';
import adjustableRateMortgage from './adjustable-rate-mortgage';
import mortgageAffordability from './mortgage-affordability';
import stampDuty from './stamp-duty';
import fixedRateMortgage from './fixed-rate-mortgage';

// Benefits
import uccFigures from './ucc-figures';
import universalCreditEligibility from './universal-credit-eligibility';
import uccStepTracker from './ucc-step-tracker';
import childBenefit from './child-benefit';


// Combine all reducers
const rootReducer = combineReducers({
    yearlyFigures,
    uccFigures,
    uccStepTracker,
    universalCreditEligibility,
    inheritanceTax,
    salary,
    pensionContribution,
    childBenefit,
    studentLoanRepayment,
    energyPerformance,
    nationalInsurance,
    incomeTax,
    dividendTax,
    adjustableRateMortgage,
    mortgageAffordability,
    stampDuty,
    fixedRateMortgage,
    salarySacrifice,
});

export default rootReducer;